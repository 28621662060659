
import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = [ "output" ]

  viewListing(event) {
    console.log("clicked!");

    var url = this.data.get("url")
    var jobId = this.data.get("job-id")
    event.preventDefault();
    event.stopImmediatePropagation();
    Rails.ajax({
      type: "POST",
      url: "/events",
      data: "job_id=" + jobId,
      success: function(data) {
        window.open(url, "_blank");
      },
      error: function(data) {
        window.open(url, "_blank");
      }
    })
    return false;
  }
}
