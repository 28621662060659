
import { Controller } from "stimulus"

export default class extends Controller {
  open() {
    const modalName = this.data.get("name")
    const jobId = this.data.get("job-id")

    console.log (`modal: ${modalName}`)
    console.log (`jobId: ${jobId}`)

    $(`${modalName}`).modal('show');
    Rails.ajax({
      type: "get",
      url: `dashboard/job_clubs/1/jobs/${jobId}/tweet`,
    })
  }
}