import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    console.log("Toggle")
  }

  toggle(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.contentTarget.classList.toggle("d-none");
  }
}