import consumer from './consumer'
import CableReady from 'cable_ready'

consumer.subscriptions.create('JobChannel', {
  connected() {
    console.log("CableReady Ready")
  },

  disconnected() {
    console.log("CableReady disconnected")
  },

  received(data) {
    if (data.cableReady) CableReady.perform(data.operations)
  }
})