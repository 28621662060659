
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tweetBody" ]

  connect() {
    let broadcastJobController = this;
    this.openCount = 0;

    // The listener for the Bootstrap event via jQuery
    $(this.element).on('show.bs.modal', (event) => {
      console.log("open modal")
    })
  }

  openModal() {
    const modalName = this.data.get("name")
    const tweetBody = $(`${modalName}`).find('textarea[data-target="tweetBody]')
    const url = this.data.get("url")

    console.log (`modal: ${modalName}`)
    console.log (`url: ${url}`)

    $(`${modalName}`).modal('show');
    fetch(`${url}`)
      .then(response => response.text())
      .then(html => {
        console.log("html:" + html)
        document.getElementById("tweetBody").value = html;
      })
  }
}
